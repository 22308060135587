/* Variables */

:root {
  --lightBlue: #496A81;
  --darkBlue: #2B3A67;
  --greyBlue: #66999B;
  --grey: #B3AF8F;
  --darkOrange: #fc9520;
  --lightGrey: #F1F2F3;

  --primaryFont: 'Montserrat'
}

/* Base Styles */

.wrapper {
  max-width: 1260px;
  max-width: 90%;
  margin: 0 auto;
}

html {
  font-size: 125%;
  letter-spacing: 1px;
  background-color: var(--lightGrey);
}

body {
  font-family: var(--primaryFont);
  font-weight: normal;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
blockquote,
a,
button {
  font-family: var(--primaryFont);
  font-weight: normal;
  margin: 0;
}


ul,
li,
a,
input,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  letter-spacing: 0px;
}

/* Display Map Styles */


.mapMarker {
  padding: 15px;
  text-align: center;
}

.markerTitle {
  font-size: 0.8rem;
}

.mapMarker>p {
  padding: 5px;
}

.directionsSection {
  background-color: var(--darkBlue);
  color: var(--grey);
  height: 75px;
  padding: 15px;
  position: relative;
  top: -75px;
}

/* NavStyles */


.sideNav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: var(--darkBlue);
  transition: 0.25s;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.sideNav>li {
  color: var(--grey);
  font-size: 2rem;
  transition: 0.25s;
}

.sideNav>li>button {
  color: var(--grey);
  font-size: 2rem;
  transition: 0.25s;
  background: none;
  border: none;
  cursor: pointer;
}

.active {
  color: var(--darkOrange);

}

.sideNav>li:hover {
  color: var(--darkOrange);
  transition: 0.25s;
}

.junoCredit {
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  width: 250px;
  left: 0;
}

.pageTitle {
  display: none;
}

.burgerButton {
  position: absolute;
  right: 20px;
  top: 37.5px;
  z-index: 15;
  opacity: 0;
  height: 25px;
  cursor: pointer;
  fill: var(--grey)
}

/* Home Page Styling */

.homePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: calc(100vh - 100px)
}

.introDescription {
  color: var(--darkBlue);
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.introLinks:hover {
  color: var(--darkOrange);
  transition: 0.25s;
}



/* MainContent Styles */

.mainContent {
  margin-left: calc(5vw + 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

/* Loading Styling */

.loadingTitle {
  text-align: center;
}

/* Search Bar Styling */

.stationAndRegionSelect {
  width: 50%;
  padding-top: 25px;
  margin: 0 auto;
  text-align: center;
}

label {
  color: var(--darkBlue);
}

/* Displaying Station Information Styling */

.stationInformation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: fit-content;
  background-color: var(--darkBlue);
  border: 2px var(--darkOrange) solid;
  border-radius: 15px;
  padding: 20px 20px;
  margin: 0 auto;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  color: var(--darkOrange);
}

.stationInformation>* {
  padding: 15px 0;
}

.displayDataButton {
  background-color: var(--darkOrange);
  border: 2px solid var(--lightBlue);
  padding: 15px;
  color: var(--lightGrey);
  border-radius: 25px;
  cursor: pointer;
  transition: 0.25s;
}

.displayDataButton:hover {
  background-color: var(--lightGrey);
  color: var(--darkOrange);
  transition: 0.25s;
}

.mapModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.closeMap {
  position: absolute;
  top: 5vh;
  left: 85vw;
  height: 50px;
  cursor: pointer;
  z-index: 3;
  fill: var(--darkOrange)
}

/* Login Styling */

.loginButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 25px;
}

.googleButton {
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #757575;
  font-size: 26px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px center;
  width: 290px;
  cursor: pointer;
}

/* Google Login CSS Credtit = https: //codepen.io/mupkoo/pen/YgddgB */

.anonButton {
  padding: 12px 16px 12px 16px;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #757575;
  font-size: 24px;
  font-weight: 500;
  background-color: white;
  width: 290px;
  cursor: pointer;
}

.loginContent {
  color: var(--darkBlue);
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (max-width:900px) {

  .sideNav {
    width: 200px;
  }

  .mainContent {
    margin-left: calc(5vw + 200px);
  }

  .junoCredit {
    width: 200px;
  }


}

@media (max-width:700px) {

  .sideNav {
    opacity: 0;
    left: -100%;
  }

  .sideNavActive {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    background-color: var(--darkBlue);
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    left: 0%;
  }

  .sideNavActive>li {
    color: var(--grey);
    font-size: 2rem;
    transition: 0.25s;
  }

  .mainContent {
    margin-left: 0;
    height: calc(100vh - 100px);
  }

  .pageTitle {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--darkOrange);
    height: 100px;
  }


  .burgerButton {
    opacity: 1;
  }

  .stationAndRegionSelect {
    width: 90%;
  }

  .headerSection {
    background-color: var(--darkBlue);
  }

  .junoCredit {
    width: 100%;
  }

  .introDescription {
    margin-top: 100px
  }

}